.user-logged-in {
  .loyalty-points-suffix {
    display: none;
  }
  .user-logged-in__link {
    .loyalty-points-value:not(:empty) + .loyalty-points-suffix {
      display: inline-block;
    }
  }
}
